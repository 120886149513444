const debug = require('debug')('mtk:api');
import { useMemo } from 'react';

import {
  useItemState,
  indexRoute,
  newItemRoute,
  getItemAction,
  updateItemAction,
  deleteItemAction,
  handleApiStart,
  handleApiSuccess,
  handleApiError,
  axios,
} from './utils';

const createUserParams = ['email', 'password', 'clientId', 'role', 'name', 'phone', 'extras', 'unitId'];
const updateUserParams = ['email', 'name', 'phone', 'status', 'extras', 'unitId'];

const users = {
  index: indexRoute('/users/', { orderAsc: false }),
  newItem: newItemRoute('/users/', { allowedParams: createUserParams }),
  item: (id, params, { history, lazy } = {}) => {
    const url = `/users/${id}`;

    const [item, setItem] = useItemState(url, params);

    const get = getItemAction(url, { params, setItem });

    const update = updateItemAction(url, {
      allowedParams: updateUserParams,
      item,
      setItem,
      onSuccess: () => get(null, 'update'),
    });

    const segmentAssign = async (segmentId) => {
      try {
        handleApiStart(setItem, 'assign', 'segment');
        const res = await axios.post(`/users/${id}/assign/segment/${segmentId}`);
        handleApiSuccess(res, setItem);
        get(null, 'update');
        return true;
      } catch (err) {
        handleApiError(err, setItem);
        return false;
      }
    };

    const segmentUnassign = async (segmentId) => {
      try {
        handleApiStart(setItem, 'unassign', 'segment');
        const res = await axios.delete(`/users/${id}/assign/segment/${segmentId}`);
        handleApiSuccess(res, setItem);
        get(null, 'update');
        return true;
      } catch (err) {
        handleApiError(err, setItem);
        return false;
      }
    };

    const changePassword = async (password) => {
      try {
        handleApiStart(setItem, 'change', 'password');
        const res = await axios.patch(`/users/${id}`, { user: { password } });
        handleApiSuccess(res, setItem, {
          message: 'La contraseña fue actualizada con éxito',
        });
        return true;
      } catch (err) {
        handleApiError(err, setItem);
        return false;
      }
    };

    const del = deleteItemAction(url, { setItem, history });

    useMemo(() => {
      if (!lazy) get();
    }, []);

    return {
      item,
      actions: { get, update, delete: del, segmentAssign, segmentUnassign, changePassword },
    };
  },
};

export default users;
