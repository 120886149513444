import { List } from 'immutable';
import { parsePhoneNumber } from 'awesome-phonenumber';
import color from 'color-string';
import { isObject } from '../utils';
import moment from 'moment';
const emailRegex = RegExp('^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$');
const urlRegex = RegExp('^https?://[^ "]+$');

export const loadCustomValidator = async (type, makeValidator) => {
  const validator = await makeValidator();
  validators[type] = validator;
};

const validators = {
  toggle: (v) => (typeof v == 'boolean' ? null : 'Valor debe ser booleano'),
  array: (v) => (List.isList(v) || Array.isArray(v) ? null : 'Valor debe ser un arreglo'),
  text: (v) => {
    return typeof v == 'string' && v.length >= 2 ? null : 'Texto debe tener al menos dos caracteres';
  },
  url: (v) => (urlRegex.test(v) ? null : 'URL inválida'),
  id: (v) => (/^[a-zA-Z0-9-_]+$/.test(v) ? null : 'ID no puede contener espacios o caracteres especiales'),
  email: (v) => (emailRegex.test(v) ? null : 'Email inválido'),
  password: (v) => (v.length >= 4 ? null : 'Password debe tener al menos 4 caracteres'),
  tel: (v) => {
    const num = parsePhoneNumber(v);
    return num.isValid() ? null : 'Teléfono inválido';
  },
  date: (v) => (moment(v).isValid() ? null : 'Fecha inválida'),
  number: (v) => (typeof v == 'number' ? null : 'Número inválido'),
  color: (v) => (color.get(v) !== null ? null : 'Color inválido'),
  lat: (v) => (v >= -90 && v <= 90 ? null : 'Latitud inválida'),
  lon: (v) => (v >= -180 && v <= 180 ? null : 'Longitud inválida'),
  json: (v) => {
    if (isObject(v)) return null;
    try {
      JSON.parse(v);
      return null;
    } catch (e) {
      return 'JSON inválido';
    }
  },
  ip: (v) => {
    const parts = v.split('.');
    if (parts.length !== 4) return 'IP inválida';
    for (let i = 0; i < parts.length; i++) {
      const n = parseInt(parts[i]);
      if (isNaN(n) || n < 0 || n > 255) return 'IP inválida';
    }
    return null;
  },
};

export default validators;
