import React from 'react';
import Case from 'case';
import classnames from 'classnames';

import { can } from '../can';
import { routes } from '../routes';
import { getName } from 'country-list';

import api from '../utils/api';
import { useQuery } from '../utils/query';

import { lockerStatusLabels, lockerStatusColors, lockerConnectionLabels, lockerConnectionColors } from '../constants';

import PageHeader from '../components/common/PageHeader';
import Pagination from '../components/common/Pagination';
import Button from '../components/common/Button';
import Toggle from '../components/common/Toggle';
import Link from '../components/common/Link';

import { List, Header, Body, Row, Cell } from '../components/common/List';
import { modeLabels } from '../constants';

import { Loading } from './PageStatus';

import css from './Lockers.css';

export const Lockers = (props) => {
  const query = useQuery(props.location, props.history);
  const { user } = api.auth();
  const { index, actions } = api.lockers.index(query.obj);

  return (
    <main className={css.root}>
      <PageHeader title="Lockers">
        {can(user, 'create', 'lockers') && (
          <Button noMargin to={routes('newLocker')}>
            Nuevo
          </Button>
        )}
      </PageHeader>
      <div className={css.toolBar}>
        <Toggle
          label="Solo lockers activos"
          onChange={(n, chk) => query.set(n, chk ? 'active' : null)}
          name={'status'}
          value={query.get('status') || false}
        />
        <Toggle
          label="Solo lockers online"
          onChange={(n, chk) => query.set(n, chk ? true : false)}
          name={'online'}
          value={query.get('online') == 'true' || query.get('online') || false}
        />
      </div>
      {index.loaded ? (
        <List className={css.root}>
          <Header>
            <Cell head>ID</Cell>
            <Cell head>Nombre</Cell>
            <Cell head>Modo</Cell>
            <Cell head>Ubicación</Cell>
            <Cell head>País</Cell>
            <Cell head>Puertas Disponibles</Cell>
            <Cell head>Estado</Cell>
            <Cell head>Conexión</Cell>
          </Header>
          <Body>
            {index.data.toJS().map((locker, index) => {
              const to = routes('locker', { id: locker.id });
              const isUnregistered = locker.status == 'unregistered';
              const country = locker.locationCountryCode ? getName(locker.locationCountryCode) : '';
              const doors = locker.doorsInfo.find((r) => r.status == 'active') || {};
              const doorsAvailable = doors.doorsTotal - doors.doorsReserved - doors.doorsOccupied;
              return (
                <Row key={`lockers-row-${index}`}>
                  <Cell>
                    <Link to={to}>{locker.id}</Link>
                  </Cell>
                  <Cell className={css.name}>
                    <Link to={to}>{locker.name}</Link>
                  </Cell>
                  <Cell>
                    <Link to={to}>
                      {locker.preferences.mode && locker.preferences.mode == 'housing' ? (
                        <span className={classnames(css.optionType, [css['border-warning']])}>
                          {modeLabels('housing')}
                        </span>
                      ) : (
                        <span className={classnames(css.optionType, [css['border-enabled']])}>
                          {modeLabels('normal')}
                        </span>
                      )}
                    </Link>
                  </Cell>
                  <Cell>{locker.locationName}</Cell>
                  <Cell>{country}</Cell>
                  <Cell>
                    {doors.doorsTotal ? (
                      <>
                        <small>
                          {doorsAvailable}/{doors.doorsTotal}
                        </small>
                        <div
                          className={css.usageBar}
                          style={{
                            width: 100,
                          }}>
                          <div
                            className={css.inner}
                            style={{
                              width: (100 * doorsAvailable) / doors.doorsTotal,
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      <small>{'Sin puertas creadas'}</small>
                    )}
                  </Cell>
                  <Cell
                    style={{
                      width: 100,
                      color: lockerStatusColors(locker.status),
                    }}
                    className={css.status}>
                    {lockerStatusLabels(locker.status)}
                  </Cell>
                  <Cell>
                    <span
                      className={css.dot}
                      style={{
                        backgroundColor: lockerConnectionColors(
                          isUnregistered ? 'unregistered' : locker.online ? 'online' : 'offline'
                        ),
                      }}
                    />
                    {locker.status == lockerConnectionLabels(locker.online ? 'online' : 'offline')}
                  </Cell>
                </Row>
              );
            })}
          </Body>
        </List>
      ) : index.loading ? (
        <Loading compact />
      ) : (
        <p>{index.error}</p>
      )}
      {index.pageInfo && <Pagination {...index.pageInfo.toJS()} onChange={query.setPage} />}
    </main>
  );
};

export default Lockers;
