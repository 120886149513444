import React from 'react';
import moment from 'moment-timezone';
import { getCodeList } from 'country-list';
import { makeEnum, makeEnumWithTranslation, timeAgo } from './utils';

/*
 ************************************
 * CLIENT CUSTOMIZATIONS
 ************************************
 */

export const API_TARGET = process.env.API_TARGET;

export const clientsMapping = [
  {
    key: 'sodimacPeru',
    id: {
      staging: 15,
      production: 2,
    },
    orderIndexExtraColumns: {
      'No. Reserva': (order) => order.extras?.nroReserva || <span style={{ color: colors.gray }}>N/A</span>,
    },
  },
  {
    key: 'cch',
    id: {
      staging: 76,
      production: 8,
    },
  },
  {
    key: 'ricohChile',
    id: {
      demo: 22,
    },
    orderIndexExtraColumns: {
      turno: (order) => order.extras?.turno || <span style={{ color: colors.gray }}>N/A</span>,
      equipo: (order) => order.extras?.equipo || <span style={{ color: colors.gray }}>N/A</span>,
      supervisor: (order) => order.extras?.supervisor || <span style={{ color: colors.gray }}>N/A</span>,
    },
  },
  {
    key: 'ricohChileCommunity',
    id: {
      demo: 23,
    },
    orderIndexExtraColumns: {
      turno: (order) => order.extras?.turno || <span style={{ color: colors.gray }}>N/A</span>,
      equipo: (order) => order.extras?.equipo || <span style={{ color: colors.gray }}>N/A</span>,
      supervisor: (order) => order.extras?.supervisor || <span style={{ color: colors.gray }}>N/A</span>,
    },
  },
  {
    key: 'universidad',
    id: {
      demo: 25,
    },
    orderIndexExtraColumns: {
      folio: (order) => order.extras?.folio || <span style={{ color: colors.gray }}>N/A</span>,
      documento: (order) => order.extras?.documento || <span style={{ color: colors.gray }}>N/A</span>,
    },
  },
  {
    key: 'promart',
    id: {
      staging: 94,
      production: 13,
    },
  },
];

export const getClientKeyByClientId = (id) => {
  const found = clientsMapping.find((o) => {
    return o.id[API_TARGET] === id;
  });
  return found ? found.key : null;
};

export const getOrderIndexExtraColumnsByClientId = (id) => {
  const found = clientsMapping.find((o) => {
    return o.id[API_TARGET] === id;
  });
  return found?.orderIndexExtraColumns;
};

/*
 ************************************
 * GENERAL
 ************************************
 */

export const modes = makeEnum(
  {
    normal: 'normal',
    housing: 'housing',
    custody: 'custody',
  },
  'mode'
);

export const modeLabels = makeEnum(
  {
    normal: 'Normal',
    housing: 'Community',
    custody: 'Custodia',
  },
  'mode'
);

export const colors = {
  green: 'var(--color-green)',
  yellow: 'var(--color-yellow)',
  blue: 'var(--color-blue)',
  yellow2: 'var(--color-yellow-2)',
  orange: 'var(--color-orange)',
  red: 'var(--color-red)',
  gray: 'var(--color-background-dark)',
  text: 'var(--color-text)',
};

export const roles = makeEnum(
  {
    admin: 'admin',
    client: 'client',
    manager: 'manager',
    viewer: 'viewer',
    courier: 'courier',
    resident: 'resident',
    noAccess: 'noAccess',
  },
  'roles'
);

export const noPasswordRoles = makeEnum(
  {
    noAccess: 'noAccess',
  },
  'noPasswordRoles'
);

export const roleLabels = makeEnum(
  {
    admin: 'Administrador',
    client: 'Admin Cliente',
    manager: 'Gestor',
    viewer: 'Revisor',
    courier: 'Courier',
    resident: 'Residente',
    noAccess: 'Sin acceso',
  },
  'roleLabels'
);

/*
 ************************************
 * ORDERS
 ************************************
 */

export const orderTypes = makeEnumWithTranslation(
  {
    delivery: {
      ricohChile: 'Entrega',
      ricohChileCommunity: 'Entrega',
      default: 'Envío',
    },
    reversal: {
      ricohChile: 'Entrega revocada',
      ricohChileCommunity: 'Entrega revocada',
      default: 'Envío revocado',
    },
    return: {
      ricohChile: 'Devolución equipo',
      ricohChileCommunity: 'Devolución equipo',
      cch: 'Admisión',
      default: 'Devolución',
    },
  },
  'orderTypes'
);

export const orderTypeColors = makeEnum(
  {
    delivery: colors.green,
    reversal: colors.yellow,
    return: colors.yellow2,
  },
  'orderTypeColors'
);

export const orderStatus = {
  delivery: makeEnum(
    {
      created: 'Creado',
      reserved: 'Reservado',
      ready: 'Listo para retiro',
      completed: 'Completado',
      cancelled: 'Cancelado',
    },
    'deliveryStatus'
  ),
  reversal: makeEnum(
    {
      reserved: 'Reservada',
      ready: 'Lista para recolección',
      completed: 'Completada',
    },
    'reversalStatus'
  ),
  return: makeEnum(
    {
      created: 'Creada',
      cancelled: 'Cancelada',
      reserved: 'Esperando entrega',
      ready: 'Lista para recolección',
      completed: 'Completada',
    },
    'returnStatus'
  ),
};

export const orderStatusLabel = ({ id, orderType, status, readyAt, reversalAt, reservedAt }) => {
  if (status == 'errored') return 'Error';
  if (orderType == 'delivery') {
    return status == 'ready' && readyAt
      ? `${orderStatus.delivery(status)} (${timeAgo(readyAt)})`
      : orderStatus.delivery(status);
  } else if (orderType == 'reversal') {
    return status == 'ready' && reversalAt
      ? `${orderStatus.reversal(status)} (${timeAgo(reversalAt)})`
      : orderStatus.reversal(status);
  } else if (orderType == 'return') {
    return status == 'reserved' && reservedAt
      ? `${orderStatus.return(status)} (${timeAgo(reservedAt)})`
      : orderStatus.return(status);
  } else {
    console.error(`unknown order type`, orderType);
    return status;
  }
};

export const orderStatusColors = ({ orderType, status, error, readyAt, reversalAt }) => {
  const readyTooLong = moment().diff(readyAt) > moment.duration(5, 'd');
  const reversalTooLong = moment().diff(reversalAt) > moment.duration(5, 'd');

  if (orderType === 'delivery') {
    if (status === 'created') return colors.green;
    if (status === 'reserved') return colors.blue;
    if (status === 'ready') return readyTooLong ? colors.red : colors.yellow2;
    if (status === 'completed') return colors.green;
    if (status === 'cancelled') return colors.red;
  } else if (orderType === 'reversal') {
    if (status === 'ready') return reversalTooLong ? colors.red : colors.orange;
    if (status === 'completed') return colors.green;
  } else if (orderType === 'return') {
    if (status === 'created') return colors.green;
    if (status === 'reserved') return colors.blue;
    if (status === 'ready') return readyTooLong ? colors.red : colors.yellow2;
    if (status === 'completed') return colors.green;
  } else if (error) {
    return colors.red;
  } else {
    return null;
  }
};

export const orderActions = makeEnum(
  {
    createOrder: 'Creación',
    reserveOrder: 'Reserva de locker',
    unreserveOrder: 'Cancelación de reserva de locker',
    readyOrder: 'Entrega en locker (lista para retiro)',
    completeOrder: 'Completación',
    updateOrder: 'Actualización',
    reversalOrder: 'Revocación',
    deleteOrder: 'Eliminación',
    cancelOrder: 'Cancelación',
  },
  'orderActions'
);

export const orderParams = makeEnum({
  locationId: 'Ubicación',
  clientId: 'Cliente',
  orderType: 'Tipo de pedido',
  courierId: 'Repartidor',
  status: 'Estado',
  width: 'Ancho',
  height: 'Alto',
  length: 'Largo',
  dropCode: 'Código de entrega',
  pickupCode: 'Código de retiro',
  courierPickupCode: 'Código de retiro del repartidor',
  pickupName: 'Destinatario',
  pickupEmail: 'Email',
  pickupPhone: 'Teléfono',
  extras: 'Extras',
  recipe: 'Receta',
  receta: 'Receta',
  folio: 'Folio',
});

/*
 ************************************
 * LOCKERS
 ************************************
 */

export const lockerStatus = makeEnum(
  {
    unregistered: 'unregistered',
    active: 'active',
    inactive: 'inactive',
  },
  'lockerStatus'
);

export const lockerStatusLabels = makeEnum(
  {
    unregistered: 'No registrado',
    active: 'Activo',
    inactive: 'Inactivo',
    defective: 'Defectuoso',
    offline: 'Offline',
  },
  'lockerStatus'
);

export const lockerStatusColors = makeEnum(
  {
    unregistered: colors.gray,
    active: colors.green,
    inactive: colors.orange,
    defective: colors.red,
  },
  'lockerStatusColors'
);

export const lockerConnectionLabels = makeEnum(
  {
    online: 'Online',
    offline: 'Offline',
  },
  'lockerConnectionLabels'
);

export const lockerConnectionColors = makeEnum(
  {
    unregistered: colors.yellow,
    online: colors.green,
    offline: colors.red,
  },
  'lockerConnectionColors'
);

/*
 ************************************
 * DOORS
 ************************************
 */

export const doorStatus = makeEnum(
  {
    active: 'Activa',
    defective: 'Defectuosa',
    inactive: 'Inactiva',
  },
  'doorStatus'
);

export const doorStatusColors = makeEnum(
  {
    active: colors.green,
    defective: colors.red,
    inactive: colors.gray,
  },
  'doorStatusColors'
);

/*
 ************************************
 * USERS
 ************************************
 */

export const userStatusLabels = makeEnum(
  {
    active: 'Activo',
    inactive: 'Inactivo',
  },
  'userStatusLabels'
);

/*
 ************************************
 * Reports
 ************************************
 */

export const reportPresets = makeEnum(
  {
    thisWeek: 'thisWeek',
    lastWeek: 'lastWeek',
    thisMonth: 'thisMonth',
    lastMonth: 'lastMonth',
  },
  'reportPresets'
);

export const reportPresetsLabels = makeEnum(
  {
    thisWeek: 'Semana en curso',
    lastWeek: 'Semana pasada',
    thisMonth: 'Mes en curso',
    lastMonth: 'Mes Pasado',
  },
  'reportPresetsLabels'
);

export const reportFrequencies = makeEnum(
  {
    hour: 'hour',
    day: 'day',
    week: 'week',
  },
  'reportFrequencies'
);

export const reportFrequenciesLabels = makeEnum(
  {
    hour: 'horaria',
    day: 'diaria',
    week: 'semanal',
  },
  'reportFrequenciesLabels'
);

// "serial" stands for the protocol used in the first version of the eLockers, boards v1 and v2
// "statesman" stands for the esp32 board using mqtt protocol to control 1 door that uses servo motor for statesman type of lockers
// "maletekV3" stand for the esp32 i2c expanded board to control 16 doors via mqtt protocol
export const doorTypeLabels = makeEnum(
  {
    serial: 'serial',
    maletekV3: 'maletekV3',
    statesman: 'statesman',
  },
  'doorTypeLabels'
);

const subsetCountryCodes = ['cl', 'pe', 'ar', 'co', 'mx', 'br', 'us', 'es'];
const allCountries = getCodeList();
export const countriesSubset = Object.keys(allCountries).reduce((acc, key) => {
  if (subsetCountryCodes.includes(key)) acc[key] = allCountries[key];
  return acc;
}, {});
