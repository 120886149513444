const debug = require('debug')('mtk:utils');

import React, { useState, useEffect, useMemo } from 'react';
import { camel } from 'case';
import moment from 'moment-timezone';
import 'moment/locale/es';
import qs from 'qs';
import axios from 'axios';

import isEqual from 'lodash/fp/isEqual';
import pick from 'lodash/fp/pick';
import { hexToHsluv, hsluvToHex } from 'hsluv';

export const validators = {};

// stolen from maletek-api
const win =
  typeof window !== 'undefined'
    ? window
    : {
        addEventListener: () => {},
        removeEventListener: () => {},
      };

export const useWindowSize = () => {
  const [size, setSize] = useState({ width: 480, height: 800 });

  useEffect(() => {
    const measure = (e) => {
      setSize({
        width: win.innerWidth,
        height: win.innerHeight,
      });
    };
    measure();
    win.addEventListener('resize', measure);
    return () => win.removeEventListener('resize', measure);
  }, []);
  return size;
};

export const makeEnum = (obj, label = '') => {
  const fn = function (k) {
    if (k == null) return k;
    if (!obj.hasOwnProperty(k)) {
      console.error('Enum "%s" does not exist for "%s"', k, label);
    }
    return obj[k];
  };
  fn.get = (k) => obj[k] || k;
  fn.has = (k) => obj.hasOwnProperty(k);
  fn.obj = { ...obj };
  fn.keys = Object.keys(obj);
  return fn;
};

export const makeEnumWithTranslation = (obj, label, _lang) => {
  let lang = _lang;
  const fn = function (k) {
    if (k == null) return k;
    if (!obj.hasOwnProperty(k)) {
      console.error('Enum "%s" does not exist for "%s"', k, label);
    }
    const chosen = obj[k];
    return isObject(chosen) ? chosen[lang] || chosen.default : chosen;
  };
  fn.setLang = (l) => {
    lang = l;
  };
  fn.has = (k) => obj.hasOwnProperty(k);
  fn.obj = { ...obj };
  fn.keys = Object.keys(obj);
  return fn;
};

export const makeRoutes = (routes) => {
  return (key, params, query) => {
    query = isObject(query) ? encodeQuery(query) : query;
    if (!routes.hasOwnProperty(key)) {
      throw new Error('Enum does not exist: ' + key);
    }
    query = query ? `?${query}` : '';
    if (!isObject(params)) return routes[key] + query;
    const p = makeEnum(params);
    return (
      routes[key].replace(/:([^/]+)/g, (match, group) => {
        return p(group);
      }) + query
    );
  };
};

export const isObject = (obj) => {
  var type = typeof obj;
  return type === 'function' || (type === 'object' && !!obj);
};

export const isArray = (arr) => {
  return Array.isArray(arr);
};

export const isFunction = (fn) => {
  return fn instanceof Function;
};

export function isEmpty(obj) {
  if (isObject(obj)) {
    return Object.keys(obj).length === 0;
  }
}

// @function allows deep-getting a prop using a dot separated path without getting an error if it doesn't exist.
// @arg {object} a nested object to be consulted
// @returns {fn} a function to be used as fn('deep.path.in.object')
export const objectGetter = (obj) => {
  return (path, def) => {
    const args = path.split('.');
    let v = { ...obj };
    for (var i = 0; i < args.length; i++) {
      v = v[args[i]];
      if (!isObject(v)) return v != null ? v : def;
    }
  };
};

// is every value in a the same as in b
export const didFieldsChange = (a, b, keys) => {
  let aa = {},
    bb = {};
  if (!isObject(a) || !isObject(b)) return true;
  if (Array.isArray(keys)) {
    keys.forEach((k) => {
      aa[k] = a[k];
      bb[k] = b[k];
    });
  } else {
    aa = pick(Object.keys(b), a);
    bb = { ...b };
  }
  // turn object props to strings fix comparison
  Object.keys(b).forEach((k) => {
    aa[k] = isObject(aa[k]) ? JSON.stringify(aa[k], null, 2) : aa[k];
    bb[k] = isObject(bb[k]) ? JSON.stringify(bb[k], null, 2) : bb[k];
  });
  return !isEqual(aa, bb);
};

// Custom PropTypes validators
export const oneOfPropsIsRequired = (toCheck) => (props, propName, componentName) => {
  if (!toCheck.some((p) => props.hasOwnProperty(p))) {
    return new Error(`One of props '${toCheck.join("' or '")}' was not specified in '${componentName}'.`);
  }
};

export const timeAgo = (date) => {
  if (!moment(date).isValid()) {
    console.error('Not a valid date');
    return '';
  }
  return moment(date).fromNow();
};

export const timeTo = (date) => {
  if (!moment(date).isValid()) {
    console.error('Not a valid date');
    return '';
  }
  return moment(date).toNow();
};

export const formatDateTime = (date, format) => {
  if (!date) return false;
  const d = moment(date).tz(moment.tz.guess());
  if (format) {
    return d.format(format);
  }
  if (d.isBefore(moment(), 'year')) {
    return d.format('D MMM YYYY, h:mma');
  }
  return d.format('D MMM h:mma');
};

export const sizeToString = (obj, prop = '') => {
  const width = camel(`${prop}-width`),
    height = camel(`${prop}-height`),
    length = camel(`${prop}-length`);
  return `${obj[height]} × ${obj[width]} × ${obj[length]} cm`;
};

export const formatDate = (date, short) => {
  if (!date) return false;
  const d = moment(date).tz(moment.tz.guess());
  if (d.isBefore(moment(), 'year')) {
    return d.format(short ? `D MMM` : `D MMMM`);
  }
  return d.format(short ? `D MMM YYYY` : `D MMMM YYYY`);
};

export const formatReportRange = (report) => {
  const timeFrom = moment(report.timeFrom).utcOffset(report.timezoneOffset || 0);
  const timeTo = moment(report.timeTo).utcOffset(report.timezoneOffset || 0);
  const sameMonth = timeFrom.month() == timeTo.month();
  const complete = timeTo.hours() == 23 && timeTo.minutes() == 59;

  const monthly = timeFrom.date() == 1 && timeTo.date() == timeTo.clone().endOf('month').date();

  let label;
  if (monthly) {
    label = sameMonth ? timeFrom.format('MMMM YYYY') : `${timeFrom.format('MMMM')} – ${timeTo.format('MMMM YYYY')}`;
  } else {
    label = `${timeFrom.format(sameMonth ? 'D' : 'D MMM')} – ${timeTo.format('D MMM YYYY')}`;
  }
  return label;
};

export const rolesByUser = (roles, user) => {
  if (user.role == 'admin') return roles;
  const rl = Object.assign({}, roles);
  delete rl.admin;
  return rl;
};

export const timeout = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const parseQuery = (s = '') => {
  const obj = qs.parse(s, { ignoreQueryPrefix: true });
  //cast int params as ints
  ['page', 'clientId', 'lockerId'].forEach((key) => {
    if (obj[key]) obj[key] = parseInt(obj[key]);
  });
  return obj;
};

export const encodeQuery = (s) => {
  return qs.stringify(s, { encode: false });
};

export const emptyStringsToNull = (obj) => {
  if (typeof obj !== 'object') return obj;
  const newObj = {};
  Object.keys(obj).forEach((k) => {
    if (typeof obj[k] === 'object' && obj[k] !== null) {
      newObj[k] = emptyStringsToNull(obj[k]);
    } else {
      newObj[k] = obj[k] === '' ? null : obj[k];
    }
  });
  return newObj;
};

export const getDefaultLocation = () => {
  const [location, setLocation] = useState({ lat: -33.43711, lon: -70.634926 });
  useMemo(() => {
    const updateLocation = async () => {
      const geo = await getGeoFromIp();
      setLocation(geo);
    };
  }, []);
  return location;
};

export const geoFromIp = async () => {
  let geo = sessionStorage.getItem('geo');
  if (geo) return JSON.parse(geo);
  const servers = ['https://ipapi.co//json/', `https://ipinfo.io/?token=${process.env.IPINFO_TOKEN}`];
  try {
    try {
      const res = await axios.get(servers[0]);
      const { latitude, longitude } = res.data || {};
      if (latitude == null || longitude == null) throw new Error('invalid lat lon');
      geo = {
        lat: latitude,
        lon: longitude,
      };
    } catch (e) {
      const res = await axios.get(servers[1]);
      let { loc } = res.data || {};
      if (!loc) throw new Error('invalid loc');
      loc = loc.split(',').map((n) => parseFloat(n));
      if (loc.some((n) => isNaN(n))) throw new Error('invalid loc');
      geo = {
        lat: loc[0],
        lon: loc[1],
      };
    }
  } catch (e) {
    debug('both ip servers failed to get geolocation from ip, falling back to default');
  }
  if (geo) sessionStorage.setItem('geo', JSON.stringify(geo));
  return geo;
};

export const getGeolocation = () => {
  const [location, setLocation] = useState();
  const geo = navigator.geolocation;

  const success = (pos) => {
    setLocation({ lat: pos.coords.latitude, lon: pos.coords.longitude });
  };
  const error = (err) => {};
  geo.getCurrentPosition(success, error);
};

// Receives an RGB color as a string, number of desired colors and desired lightness difference
// from initial value which starts by default from 80 as minimun contrast over white
export const monochromaticSchemeFromColor = (rgbColor, n, range = 80, initialLightness = 80) => {
  const colors = [];
  const steps = range / n;
  const color = hexToHsluv(rgbColor);
  for (let i = 0; i < n; i++) {
    const h = color[0];
    const s = color[1];
    const l = initialLightness - steps * i;
    colors.push(hsluvToHex([h, s, l]));
  }
  return colors;
};

export const formatMinutesToDays = (m) => {
  const d = Math.round(m / 60 / 24 + Number.EPSILON).toLocaleString(undefined, { maximumFractionDigits: 0 });
  const rest = Math.round(((m / 60) % 24) + Number.EPSILON).toLocaleString(undefined, { maximumFractionDigits: 0 });
  return rest === 0 ? `${d} días*` : `${d} días y ${rest} horas*`;
};

export const codeInputRegex = /[^0-9-A-Za-z]/g;
