import React from 'react';
import { Link } from 'react-router-dom';
import api from '../utils/api';

import { useQuery } from '../utils/query';
import { routes } from '../routes';
import { can } from '../can';

import { getName } from 'country-list';
import PageHeader from '../components/common/PageHeader';
import { List, Header, Body, Row, Cell } from '../components/common/List';
import Pagination from '../components/common/Pagination';
import Button from '../components/common/Button';

import { Loading } from './PageStatus';

import css from './Locations.css';

export const Locations = (props) => {
  const query = useQuery(props.location, props.history);
  const { user } = api.auth();
  const { index } = api.locations.index(query.obj);

  return (
    <main className={css.root}>
      <PageHeader title="Ubicaciones" {...index}>
        {can(user, 'create', 'locations') && (
          <Button noMargin to={routes('newLocation')}>
            Nueva
          </Button>
        )}
      </PageHeader>

      {index.loaded ? (
        <List className={css.root}>
          <Header>
            <Cell head>Id</Cell>
            <Cell head>Nombre</Cell>
            <Cell head>Dirección</Cell>
            <Cell head>País</Cell>
          </Header>
          <Body>
            {index.data.toJS().map((location, i) => {
              const to = routes('location', { id: location.id });
              return (
                <Row key={`location-row-${i}`}>
                  <Cell>
                    <Link to={to}>{location.id}</Link>
                  </Cell>
                  <Cell>
                    <Link to={to}>{location.name}</Link>
                  </Cell>
                  <Cell>
                    <Link to={to}>{location.address}</Link>
                  </Cell>
                  <Cell>
                    <Link to={to}>{location.countryCode ? getName(location.countryCode) : ''}</Link>
                  </Cell>
                </Row>
              );
            })}
          </Body>
        </List>
      ) : (
        <Loading compact />
      )}
      {index.pageInfo && <Pagination {...index.pageInfo.toJS()} onChange={query.setPage} />}
    </main>
  );
};

export default Locations;
