const debug = require('debug')('mtk:react:location');

import React from 'react';

import api from '../utils/api';
import { getDefaultLocation } from '../utils';
import { countriesSubset } from '../constants';

import { useForm } from '../utils/form';
import { can } from '../can';
import { Loading, NotFound } from './PageStatus';

import PageHeader from '../components/common/PageHeader';
import Map from '../components/common/Map';
import Button from '../components/common/Button';
import Input from '../components/common/Input';
import TextArea from '../components/common/TextArea';
import Select, { objectToOptions } from '../components/common/Select';

import grid from '../styles/grid.css';
import css from './Location.css';
import Units from '../components/Units';

const locationFields = {
  name: ['text', true],
  address: ['text', true],
  lon: ['lon', true],
  lat: ['lat', true],
  description: ['text'],
};

const Location = ({ history, match }) => {
  const { id } = match.params;
  const { user } = api.auth();
  const defaultLocation = getDefaultLocation();

  const countryOptions = objectToOptions(countriesSubset);

  const { item, actions } = api.locations.item(id, {}, { history });

  const form = useForm(locationFields, item.data, { submit: actions.update });

  const onMapChange = (loc) => {
    form.setValues(loc);
  };

  const onDelete = () => {
    actions.delete();
  };

  if (item.error && item.status == 404) return <NotFound />;
  if (!item.loaded) return <Loading />;

  return (
    <main className={css.root}>
      <PageHeader section="locations" title={form.getValue('name')} dirty={form.dirty} autoSave {...item}>
        {can(user, 'delete', 'locations') && (
          <Button noMargin confirm="¿Eliminar esta Ubicación?" destructive onClick={onDelete}>
            Eliminar
          </Button>
        )}
      </PageHeader>

      <div className={grid.grid}>
        <section className={grid.half}>
          <Input name="name" label="Nombre" placeholder="Maletek" form={form} />
          <Input name="address" label="Dirección" placeholder="Bustamante 24, Providencia, Santiago" form={form} />
          <Select name="countryCode" isClearable={false} options={countryOptions} label="País" form={form} />
          <TextArea
            name="description"
            label="Como encontrar el grupo de lockers una vez en la dirección"
            rows={3}
            placeholder="En el sector cajas, frente a caja 55, junto a servicio al cliente"
            form={form}
          />
          <Input name="lat" disabled label="Latitud" placeholder={defaultLocation.lat} form={form} />
          <Input name="lon" disabled label="Longitud" placeholder={defaultLocation.lon} form={form} />

          <Units locationId={id} />
        </section>
        <section className={grid.half}>
          <h3> Ubicación en el mapa</h3>
          <p>
            <small>
              Ajusta la posición de la cruz en el mapa a la ubicación exacta del grupo de lockers en el lugar. Puedes
              hacer zoom para mayor presición.
            </small>
          </p>
          <Map
            crosshair
            lat={parseFloat(form.getValue('lat'))}
            lon={parseFloat(form.getValue('lon'))}
            onChange={onMapChange}
          />
        </section>
      </div>
    </main>
  );
};

export default Location;
