import React from 'react';
import { Link } from 'react-router-dom';

import { can } from '../can';
import { routes } from '../routes';
import api from '../utils/api';
import { useQuery } from '../utils/query';

import { roles, roleLabels, userStatusLabels } from '../constants';

import { Loading } from './PageStatus';

import PageHeader from '../components/common/PageHeader';
import Button from '../components/common/Button';
import Select, { indexToOptions } from '../components/common/Select';
import Input from '../components/common/Input';

import Toggle from '../components/common/Toggle';
import { List, Header, Body, Row, Cell } from '../components/common/List';
import Pagination from '../components/common/Pagination';

import css from './Users.css';

const Users = (props) => {
  const query = useQuery(props.location, props.history, { status: 'active' });
  const { user } = api.auth();
  const { index, actions } = api.users.index(query.obj);
  const { actions: clientsActions } = api.clients.index(null, { lazy: true });
  const getClients = async (search) => {
    console.log(search);
    return indexToOptions(await clientsActions.get({ search, perPage: 50 }));
  };

  const isAdmin = user.role == roles('admin');

  return (
    <main className={css.root}>
      <PageHeader title="Usuarios">
        {can(user, 'create', 'users') && (
          <Button noMargin to={routes('newUser')}>
            Nuevo
          </Button>
        )}
      </PageHeader>
      <div className={css.toolBar}>
        <Input
          className={css.select}
          label="Buscar Usuario"
          name="search"
          onChange={query.set}
          value={query.get('search')}
          placeholder="Nombre o Email"
        />
        {can(user, 'index', 'clients') && (
          <Select
            className={css.select}
            label="Cliente"
            name="clientId"
            value={query.get('clientId')}
            onChange={query.set}
            placeholder="Todos"
            loadOptions={getClients}
          />
        )}

        <Toggle
          label="Solo usuarios activos"
          onChange={(name, checked) => {
            query.set(name, checked ? 'active' : null);
          }}
          name={'status'}
          value={query.get('status') || false}
        />
      </div>
      {index.loaded ? (
        <List className={css.root}>
          <Header>
            <Cell head>Id</Cell>
            <Cell head>Nombre</Cell>
            <Cell head>Email</Cell>
            <Cell head>Teléfono</Cell>
            <Cell head>Rol</Cell>
            <Cell head>Status</Cell>
            {isAdmin && <Cell head>Cliente</Cell>}
          </Header>
          <Body>
            {index.data.toJS().map((u, i) => {
              const to = routes('user', { id: u.id });
              return (
                <Row className={!can(user, 'show', 'users', u) ? css.readOnly : null} key={`user-row-${i}`}>
                  <Cell>
                    <Link to={to}>{u.id}</Link>
                  </Cell>
                  <Cell>
                    <Link to={to}>{u.name}</Link>
                  </Cell>
                  <Cell>
                    <Link to={to}>{u.email}</Link>
                  </Cell>
                  <Cell>
                    <Link to={to}> {u.phone}</Link>
                  </Cell>
                  <Cell>
                    <Link to={to}>{roleLabels(u.role)}</Link>
                  </Cell>
                  <Cell>
                    <Link to={to}>{userStatusLabels(u.status)}</Link>
                  </Cell>
                  {isAdmin && (
                    <Cell>
                      {u.clientId ? (
                        <Link to={routes('client', { id: u.clientId })}>{u.clientName || u.clientId}</Link>
                      ) : (
                        'Admin'
                      )}
                    </Cell>
                  )}
                </Row>
              );
            })}
          </Body>
        </List>
      ) : (
        <Loading compact />
      )}
      {index.pageInfo && <Pagination {...index.pageInfo.toJS()} onChange={query.setPage} />}
    </main>
  );
};

export default Users;
