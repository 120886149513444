const debug = require('debug')('mtk:react:user');

import React from 'react';

import { modes, roles, roleLabels, noPasswordRoles } from '../constants';
import { can } from '../can';

import api from '../utils/api';
import { useForm } from '../utils/form';
import validators from '../utils/validate';

import PageHeader from '../components/common/PageHeader';
import Button from '../components/common/Button';

import Input from '../components/common/Input';
import Select, { indexToOptions, objectToOptions } from '../components/common/Select';

import grid from '../styles/grid.css';

const userFields = {
  name: ['text'],
  email: ['email', true],
  role: ['text', true],
  clientId: ['text'],
  clientName: ['text'],
  password: [
    'password',
    null,
    {
      required: (v, values) => {
        return !noPasswordRoles.has(values.get('role'));
      },
      validate: (v, values) => {
        if (!noPasswordRoles.has(values.get('role'))) {
          validators.password(v);
        } else {
          return null;
        }
      },
    },
  ],
  password_confirm: [
    'password',
    null,
    {
      required: (v, values) => !noPasswordRoles.has(values.get('role')),
      validate: (v, values) => (v == values.get('password') ? null : 'las contraseñas deben coincidir'),
    },
  ],
  phone: ['tel'],
  clientId: ['number'],
  status: ['text'],
};

const UserNew = ({ match, history }) => {
  const { user } = api.auth();
  const { item, actions } = api.users.newItem({ history });

  const { actions: clientsActions } = api.clients.index(null, { lazy: true });

  const form = useForm(userFields, item.data);

  const isAdmin = user.role == 'admin';
  const readOnly = !can(user, 'update', 'users', item.data);
  const name = form.getValue('name');

  const getClients = async (search) => {
    const index = await clientsActions.get({ search, perPage: 50 });
    const options = indexToOptions(index, { value: 'id', label: 'name' });
    return options;
  };
  const onClientChange = (name, option) => {
    form.setValue('clientId', option);
  };

  const handleCreate = async () => {
    if (form.validate(null, true)) {
      let values = form.getValues();
      if (!isAdmin) values = values.set('clientId', user.clientId);
      actions.create(values);
    }
  };

  let roleOptions = objectToOptions(roleLabels.obj);

  // remove `resident` role for users that are not housing
  if (!isAdmin && user.clientMode !== modes('housing')) {
    roleOptions = roleOptions.filter((o) => o.value !== 'resident');
  }

  return (
    <main>
      <PageHeader section="users" title={`Nuevo usuario ${name ? `: ${name}` : ''}`} {...item} />

      <div className={grid.grid}>
        <section className={grid.half}>
          <Input
            name="email"
            label="E-Mail"
            placeholder="user@maletek.cl"
            type="email"
            readOnly={readOnly}
            form={form}
          />

          <Select label="Rol" name="role" options={roleOptions} isClearable={false} readOnly={readOnly} form={form} />

          {!noPasswordRoles.has(form.getValue('role')) && (
            <>
              <Input
                name="password"
                label={'Contraseña'}
                placeholder={'•••••••••'}
                type="password"
                skipSubmit
                readOnly={readOnly}
                form={form}
              />

              <Input
                name="password_confirm"
                label={'Repetir contraseña'}
                placeholder={'•••••••••'}
                type="password"
                skipSubmit
                readOnly={readOnly}
                form={form}
              />
            </>
          )}
        </section>
        <section className={grid.half}>
          <Input name="name" label="Nombre" form={form} />
          <Input name="phone" placeholder="+56 9 9999 9999" label="Teléfono Movil" type="tel" form={form} />

          {form.getValue('role') !== roles('admin') && (
            <Select
              label="Cliente"
              name="clients"
              onChange={onClientChange}
              loadOptions={getClients}
              form={form}
              noOptionsMessage={(a) => {
                if (a.inputValue) {
                  return `No hay clientes que contengan "${a.inputValue}"`;
                }
                return 'No hay clientes.';
              }}
            />
          )}
        </section>
        <section className={grid.full}>
          <Button onClick={handleCreate}>Crear usuario</Button>
        </section>
      </div>
    </main>
  );
};

export default UserNew;
